import React from 'react';
import ReactDOM from 'react-dom';
import ScDecoder from 'Services/json/core__decoder';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { TCReact } from './core__tc-react';
export const Translations = new TranslationsProvider('TCApp');

class TCApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data) {
    Translations.setAll(data);
    const ReactInstance = this.getReactApp();
    const jsx = (
      <ReactInstance
        theme={this.appConfig?.theme}
        title={this.appConfig?.title}
        dynamicHtml={this.appConfig.dynamichtml}
      />
    );

    ReactDOM['render'](jsx, this.appSection);
  }

  getReactApp() {
    TCReact.strToTranslate = [
      'text.new.terms',
      'text.accept',
      'text.new.terms.here',
      'text.privacy.policy',
      'text.contact.us',
      'text.please.read',
      'and.and.word',
      'text.inorder.to.continue',
      'text.any.questions',
    ];
    return TCReact;
  }
}

export default TCApp;
