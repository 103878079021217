import styled from 'styled-components';
import { FUNCTIONAL, GREYS } from 'UI/globals/colours';
import { getModalWidth } from '../../../components/GameLoadingApp/component/helper/gameAppConfig';
import { BREAKPOINT_S, BREAKPOINT_M } from 'UI/globals/breakpoints';

// Div to load game iframe
// Image with reactbet domain not available
export const MD = styled.div`
  background-size: cover;
  background-image: url(${props => props?.image});
  padding-top: 48px;
  text-align: center;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-top: 0;
  }
  iframe {
    width: ${props => (props.fullScreen ? props.scnWidth + 'px' : '1000px')};
    height: ${props =>
      props.fullScreen
        ? props.scnHeight - 84 + 'px'
        : props.windowInnerHeight + 'px'};
    @media (max-width: ${BREAKPOINT_S}) {
      width: 100%;
    }
  }
`;

// Div to load game iframe
export const ID = styled.div`
  width: 390px;
  height: 390px;
  border-radius: 50% 0% 50% 50%;
  margin: 0 auto;
  overflow: hidden;
  background-image: url(${props => props?.image}),
    url(${props => props?.defaultimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

// Div to load Favourites
export const FD = styled.div`
  display: flex;
  color: ${GREYS.white};
  font-weight: 900;
  cursor: pointer;
  justify-content: center;
  padding: 4px;
`;

// Div to display popup text
export const PTX = styled.div`
  font-size: 14px;
  text-align: center;
  a {
    text-decoration: underline;
    outline: none;
  }
`;

// Div to display popup content
export const CONTENT = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  margin-bottom: ${({ marginB }) => marginB && '4px'};
`;

// Div to display popup description
export const PDC = styled.div`
  font-size: 14px;
  text-align: center;
`;

// Div to display popup buttons
export const PST = styled.div`
  display: block;
  justify-content: center;
  button {
    margin-right: 8px;
    margin-top: 12px;
  }
`;

// Div to override modal styles
export const MStyles = {
  modal: {
    padding: '24px',
    width: getModalWidth(BREAKPOINT_M),
    borderRadius: '4px',
    margin: 0,
  },
  closeButton: {
    display: 'none',
  },
};

// Div to display casino  back button
export const CBB = styled.div`
  display: flex;
  color: ${GREYS.white};
  font-size: 14px;
  width: 1000px;
  margin: 8px auto;
  cursor: pointer;
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;

// Div to change color of full screen icon
export const EC = styled.span`
  color: ${GREYS.white};
  cursor: pointer;
  padding: 8px;
`;

// Main div to display bottom items below iframe
export const BMD = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px auto;
  align-items: center;
  width: ${props => (props.fullScreen ? props.scnWidth + 'px' : '1000px')};
  @media (max-width: ${BREAKPOINT_S}) {
    display: none;
  }
`;

// Div to display favourites and expand view
export const FED = styled.div`
  display: flex;
  align-items: center;
`;

// Div to display bonus popup
export const BP = styled.div`
  font-weight: bold;
  color: ${GREYS.white};
`;
export const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 16px;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  text-align: center;
`;

export const BK = styled.span`
  color: ${GREYS.white};
  margin-top: 4px;
`;

export const TIMEOUT = styled.div`
  display: flex;
  justify-content: center;
`;

export const ERRORMSG = styled.div`
  height: 172px;
  width: 1000px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props =>
    props.theme.dark ? GREYS.silverD : GREYS.white};
  color: ${FUNCTIONAL.negative};
`;

export const MODAL_BODY = styled.div`
  margin: 16px 0px;
`;
