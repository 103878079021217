import styled from 'styled-components';
import { GREYS, BRAND } from 'UI/globals/colours';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
`;
export const Text = styled.div`
  color: ${({ theme }) => (theme.dark ? GREYS.white : GREYS.black)};
  margin-bottom: 16px;
`;
export const Link = styled.a`
  color: ${({ theme }) => (theme.dark ? BRAND.secondary : BRAND.primary)};
  text-decoration: underline;
`;
export const TitleText = styled.p`
  color: ${BRAND.secondary};
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: bold;
`;
