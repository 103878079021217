import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MStyles } from 'CASINO_UI/apps/GameLoadingApp/GameLoading';
import { Modal } from 'CORE__UI/globals/Modal/core__modal'; //caution: don't use local import
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import ModalButton from 'UI/buttons/ModalButton';
import { closeModalonURLS, putTsAndCs } from './core__tc-utils';
import { Translations } from './core__tc-app';
import {
  Container,
  Text as Text_CORE,
  Link,
  TitleText,
} from 'CORE__UI/apps/TCApp/core__tc-react';
import { Text as Text_LOCAL } from 'UI/apps/TCApp/TCreact';
import { GLOBAL_PATH } from 'Services/global/core__constants';
import { FSBTheme } from 'Services/core__fsb-theme';
import { getComponent } from 'Services/core__imports';
const Text = getComponent(Text_LOCAL, Text_CORE);

export const TCReact = ({ theme, title, dynamicHtml }) => {
  const [accessToken, setAccessToken] = useState(false);
  const [tsAndCs, setTsAndCs] = useState(false);
  const [showModal, setShowModal] = useState(false);
  //React lifecycle for listening to the pubsub events when the app is loaded
  useEffect(() => {
    // Pubsub event listen for when the session is being updated
    const sessionUpdated = PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) {
        setAccessToken(session.accessToken);
        setTsAndCs(session.tsAndCs);
        if (
          !session.tsAndCs &&
          !closeModalonURLS.includes(window.location.pathname)
        ) {
          setShowModal(true);
        }
      }
    });

    // Pubsub event listen for when the session is being created
    const sessionCreated = PubSub.listen(PubsubEvents.SESSION_CREATED, () => {
      const session = FSBCustomerSession.getSession();
      if (session) {
        setAccessToken(session.accessToken);
        setTsAndCs(session.tsAndCs);
        if (
          !session.tsAndCs &&
          !closeModalonURLS.includes(window.location.pathname)
        ) {
          setShowModal(true);
        }
      }
    });

    // in the case a session isn't updated or created but page manages to change - check conditions on render
    const session = FSBCustomerSession.getSession();
    if (session) {
      setAccessToken(session.accessToken);
      setTsAndCs(session.tsAndCs);
      if (
        !session.tsAndCs &&
        !closeModalonURLS.includes(window.location.pathname)
      ) {
        setShowModal(true);
      }
    }

    return () => {
      sessionUpdated?.unsubscribe();
      sessionCreated?.unsubscribe();
    };
  }, []);

  return (
    <FSBTheme theme={theme}>
      {showModal && accessToken && !tsAndCs && (
        <Modal cantEscape={true} open={showModal} center styles={MStyles}>
          <Container>
            {title && <TitleText>{title}</TitleText>}
            {dynamicHtml ? (
              <Container
                dangerouslySetInnerHTML={{ __html: dynamicHtml }}
              ></Container>
            ) : (
              <React.Fragment>
                <Text>
                  {Translations.get('text.please.read')}
                  <Link href={GLOBAL_PATH.TAC}>
                    {Translations.get('text.new.terms.here')}
                  </Link>{' '}
                  {Translations.get('and.and.word')}{' '}
                  <Link href={GLOBAL_PATH.PRIVACY_POLICY}>
                    {Translations.get('text.privacy.policy')}
                  </Link>{' '}
                  {Translations.get('text.inorder.to.continue')}
                </Text>
                <Text>
                  {Translations.get('text.any.questions')}{' '}
                  <Link href={GLOBAL_PATH.CONTACT_US}>
                    {Translations.get('text.contact.us')}
                  </Link>
                </Text>
              </React.Fragment>
            )}

            <ModalButton
              onClick={() => putTsAndCs(true, accessToken, setShowModal)}
              label={Translations.get('text.accept')}
            />
          </Container>
        </Modal>
      )}
    </FSBTheme>
  );
};

TCReact.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  dynamicHtml: PropTypes.string,
};
