import { putRequest } from 'Services/http/core__axios';

export const putTsAndCs = async (value, token, setShowModal) => {
  if (value && token) {
    const fetchUrl = `/fsb-api-rest/customer.fsb?access_token=${token}`;
    const tsAndCs = value;
    return putRequest(fetchUrl, {
      tsAndCs: tsAndCs,
    }).then(res => {
      if (res?.customer?.tsAndCs) {
        setShowModal(false);
      }
    });
  }
};

export const closeModalonURLS = [
  '/terms-and-conditions/',
  '/maximum-winnings/',
  '/responsible-gaming/',
  '/privacy-policy/',
  '/contact-us/',
  '/virtual-rules/',
];
