let config = {};

export function setAppConfig(obj) {
  config = obj;
}

export function getAppConfig() {
  return config;
}

/**
 * Set the reality check modal width
 */
export const getModalWidth = BREAKPOINT_M => {
  if (typeof screen !== 'undefined') {
    return screen.width < BREAKPOINT_M.replace('px', '') ? '100%' : '480px';
  }
};
